export const FORGD_SYSTEM_COINGECKO_ID = 'forgd'
export const FORGD_SYSTEM_PROJECT_ID = '00000000-a270-4d52-8cb0-6e6605dce512'

export const MAX_SELECTED_QUOTES = 5 // max number of quotes a user can select for requote or acceptance
export const MAX_QUOTES_SUBMITTED_PER_MARKET_MAKER_PER_ROUND = 3
export const MAX_DAYS_FOR_USER_TO_MAKE_DECISION_ON_QUOTES = 14

/**
 * Minimum number of quotes required to send a competition update notification.
 * If the number of quotes is below this threshold, the notification won't be sent.
 */
export const COMPETITION_UPDATE_MIN_QUOTES_QTY = 2
export const MAX_MARKET_MAKERS_PER_PROJECT = 8
